<template>
  <section id="wizard-vendor">
    <b-row class="mb-2">
      <b-col
        md="8"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t('createReturns') }}</h1>
        <h5 class="text-primary">
          {{ $t('configCreateReturns') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          variant="danger"
          @click="goBack()"
        >
          {{ $t("dataGeneric.goBack") }}
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="showData"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.create')"
        :back-button-text="$t('dataGeneric.goBack')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted($event)"
      >
        <tab-content
          :title="$t('contents.categoryData')"
          :before-change="validationFormInfo"
        >
          <!-- DATA VENDOR -->
          <validation-observer
            ref="dataRules"
            tag="form"
          >
            <b-row class="m-2 align-items-center">
              <b-col
                cols="11"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("contents.categoryData") }}
                </h5>
                <small class="text-muted">
                  {{ $t('infoSeller') }}
                </small>
              </b-col>
              <b-col md="11">
                <validation-provider
                  v-slot="{ errors }"
                  name="orderId"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('Order')+' *'"
                    :invalid-feedback="$t('requiredOrder')"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  >
                    <div @click="$refs['order-modal'].show()">
                      <b-form-input
                        id="orderId"
                        v-model="orderId"
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false : null"
                        maxlength="130"
                        :placeholder="$t('Order')"
                        required
                      />
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="1"
                class="align-items-center align-self-center"
              >
                <b-button
                  variant="info"
                  @click="$refs['order-modal'].show()"
                >
                  {{ $t('Select') }}
                </b-button>
              </b-col>

              <b-col
                v-if="!isVendor"
                md="11"
              >
                <b-form-group
                  :label="$t('Vendedor')"
                  disabled
                >
                  <b-form-input
                    id="vendor"
                    v-model="vendorName"
                    required
                    :placeholder="$t('Vendedor')"
                    maxlength="130"
                    autocomplete="new-password"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          :title="$t('payment')"
          :before-change="validationFormPrice"
        >
          <!-- ADDRESS VENDOR -->
          <validation-observer
            ref="priceRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t('payment') }}
                </h5>
                <small class="text-muted">
                  {{ $t('infoPayment') }}
                </small>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('methodPayment')">
                  <b-form-select
                    v-model="paymentGateway"
                    disabled
                  >
                    <b-form-select-option
                      v-for="v in Object.keys(methodPayment)"
                      :key="v"
                      :value="v"
                    >
                      {{ methodPayment[v] }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('editContent.status')">
                  <b-form-select
                    v-model="statusPay"
                    disabled
                  >
                    <b-form-select-option
                      v-for="v in Object.keys(status)"
                      :key="v"
                      :value="v"
                    >
                      {{ status[v] }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="amount"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('amount')+' *'"
                    :invalid-feedback="$t('requiredQuantity')"
                  >
                    <b-form-input
                      id="amount"
                      v-model="amount"
                      required
                      disabled
                      :placeholder="$t('refundQuantity')"
                      :state="errors.length > 0 ? false : null"
                      maxlength="20"
                      type="number"
                      autocomplete="new-password"
                      @blur="checkMount"
                    />
                  </b-form-group>
                  <div class="amount_info d-block">
                    <p>{{ $t('errMsgRefundGreater',{totalAmount:totalAmount}) }}</p>
                    <p>{{ $t('shippingCostMsg',{shippingCostMsg:shippingCost}) }}</p>
                  </div>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="reason"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('refundReason')"
                    :invalid-feedback="$t('required')"
                  >
                    <b-form-textarea
                      id="reason"
                      v-model="reason"
                      rows="4"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('refundReason')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :label="$t('returnProducts')">
                  <b-form-checkbox-group
                    v-model="selected"
                    class="d-flex flex-column"
                  >
                    <div
                      v-for="pr in products"
                      :key="pr.node.id"
                      class="align-items-center d-flex m-1"
                    >
                      <b-form-checkbox
                        :id="pr.node.id"
                        :value="pr.node"
                      />
                      <label
                        :for="pr.node.id"
                        style="font-size: small;"
                      >
                        {{ pr.node.itemName }} {{ pr.node.itemPrice }} € X
                      </label>
                      <b-form-input
                        :id="'stock_' + pr.node.id"
                        type="number"
                        :value="pr.node.itemQuantity"
                        min="0"
                        :max="pr.node.itemQuantity"
                        class="ml-1 mr-1"
                        style="width: 70px;"
                        @blur="checkStock()"
                      />
                    </div>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>

    <b-modal
      ref="order-modal"
      size="xl"
      hide-footer
      :title="$t('shopOrder')"
    >
      <div class="d-block ">
        <order-selector @order="selectOrder" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { showToast, messageError, base64Encode } from '@/store/functions'

import {
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BFormSelectOption,
  BFormGroup,
  BFormTextarea,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  VBTooltip,
  BOverlay,
} from 'bootstrap-vue'

import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import * as constants from '@core/utils/constants'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'

import OrderSelector from '@/views/common/OrderSelectorModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    FormWizard,
    TabContent,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormSelectOption,
    BFormTextarea,
    OrderSelector,
    BFormCheckboxGroup,
    BOverlay,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      totalAmount: 0,
      totalRefunded: 0,
      shippingCost: 0,
      next: false,
      statusPay: 'PEN',
      paymentGateway: 'STR',
      usuario: null,
      phone: null,
      amount: 0,
      reason: this.$t('requestedByClient'),
      paymentId: null,
      bankName: null,
      iban: null,
      stripeUser: null,
      address: null,
      province: null,
      city: null,
      postalCode: null,
      country: null,
      allCountry: [],
      commissionValue: 0,
      commissionType: 'NON',
      isFeatured: false,
      vatNumber: 21,
      userData: getUserData(),
      id: this.$route.params.id,
      idSelectCard: null,
      vendorImageSrc: null,
      date: null,
      vendorImage: null,
      methodPayment: {
        STR: 'Stripe',
        APP: 'Apple InAppPurchase',
        GOW: 'Google InAppPurchase',
      },
      imageClicked: null,
      refundCreator: null,
      vendorId: null,
      orderId: null,
      order: null,
      userId: null,
      vendorName: null,
      userName: null,
      products: [],
      selected: [],
      showData: false,
      status: {
        PEN: this.$t('pending'),
        COM: this.$t('completed'),
        CAN: this.$t('cancelled'),
      },
      isVendor: false,
    }
  },
  watch: {
    selected() {
      try {
        let amount = this.shippingCost
        this.selected.forEach(element => {
          const stock = document.getElementById(`stock_${element.id}`).value
          amount += element.itemPrice * stock
        })
        this.amount = this.roundToTwoDecimals(amount)
      // eslint-disable-next-line no-empty
      } catch (error) {

      }
    },
    vendorImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.vendorImageSelected = true
              this.vendorImageSrc = value
            })
            .catch(() => {
              this.vendorImageSrc = null
            })
        } else {
          this.vendorImageSrc = null
        }
      }
    },
  },
  mounted() {
    this.isVendor = this.userData.groups.edges[0].node.name === constants.vendor
  },
  methods: {
    checkStock() {
      let amount = this.shippingCost
      try {
        this.selected.forEach(element => {
          const stock = document.getElementById(`stock_${element.id}`).value
          amount += element.itemPrice * stock
        })
        this.amount = this.roundToTwoDecimals(amount)
      // eslint-disable-next-line no-empty
      } catch (error) {

      }
    },
    checkMount() {
      if (this.amount > this.totalAmount) this.amount = this.roundToTwoDecimals(this.totalAmount)
    },
    selectOrder(data) {
      this.next = true
      this.hideModal()
      this.orderId = data.orderId
      this.order = data.id
      this.products = data.products.edges

      this.totalRefunded = data.totalRefunded

      if (this.totalRefunded) {
        this.totalAmount = this.roundToTwoDecimals(data.totalAmount - this.totalRefunded)
      } else {
        this.totalAmount = this.roundToTwoDecimals(data.totalAmount)
      }

      this.amount = data.shippingCost
      this.shippingCost = data.shippingCost

      try {
        this.vendorId = data.vendor.id
        this.vendorName = data.vendor.vendorName
      } catch (error) {
        showToast(this.$t('orderSellerError'), 2, this)
        this.next = false
      }
      try {
        this.userId = data.customer.id
        this.userName = data.customer.username
      } catch (error) {
        showToast(this.$t('orderBuyerError'), 2, this)
        this.next = false
      }
    },
    hideModal() {
      this.$refs['order-modal'].hide()
    },
    roundToTwoDecimals(value) {
      if (typeof value !== 'number') {
        throw new Error(this.$t('valueNotvalid'))
      }
      const roundedValue = Number(value.toFixed(2))
      if (Number.isNaN(roundedValue)) {
        return value
      }
      return roundedValue
    },
    goBack() {
      window.history.back()
    },
    formSubmitted(event) {
      this.showData = true
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      event?.preventDefault()
      const query = `
                mutation($reason: String,$date: DateTime) {
                    createRefund(input:{
                        user:"${this.userId}",
                        vendor:"${this.vendorId}",
                        order:"${this.order}",
                        client:"${this.userData.profile.client.id}",
                        status:${this.statusPay},
                        paymentGateway:${this.paymentGateway},
                        reason: $reason,
                        amount:${this.amount},
                        products:[${this.getIdArray(this.selected)}]
                        date: $date,
                        refundCreator:${this.userData.id},
                    }){
                        refund{
                                id


                            }
                        }
                    }
                `
      const variables = {
        reason: this.reason,
        date: new Date(),
      }
      data.append('variables', JSON.stringify(variables))
      data.append('query', query)
      axios.post('', data, config).then(res => {
        messageError(res, this)

        showToast(this.$t('refundCreatedSuccess'),
          1, this)
        this.$router.push({ name: 'refunds' })
        this.showData = false
      })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 2, this)
          this.showData = false
        })
    },
    getIdArray(array) {
      const res = []
      array.forEach(element => {
        res.push(element.id)
      })
      return res
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.dataRules.validate().then(success => {
          if (success && this.next) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationFormPrice() {
      return new Promise((resolve, reject) => {
        this.$refs.priceRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "vue-context/src/sass/vue-context";

#wizard-vendor .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#wizard-vendor #add-new-category-sidebar {
  #wizard-vendor .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

#wizard-vendor .tamanio {
  width: 50%;
}

#wizard-vendor .amount_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}
</style>
